<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="sceneDetails">
            <div slot="header" class="a-fs-16 a-fw-b a-flex-rfsfd">
                <span>订单信息</span>
                <span class="a-mlr-17 a-c-normal a-fs-12" v-if="couponOrderInfo">订单编号：{{ couponOrderInfo.orderSn?couponOrderInfo.orderSn:'-' }}</span>
            </div>
            <div class="a-c-master a-fs-16 a-fw-b">用户信息</div>
            <div class="a-flex-rfsfs a-flex-wrap">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">用户名称</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.nickName?couponOrderInfo.nickName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">手机号</span>
                    <span class="content a-c-master">{{ userInfo.dataPermission == 77 ? util.hidePhoneNumber(couponOrderInfo.mobile) : couponOrderInfo.mobile }}</span>
                </div>
            </div>
            <div class="a-c-master a-fs-16 a-fw-b mt64">支付信息</div>
            <div class="a-flex-rfsfs a-flex-wrap">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付渠道</span>
                    <span class="content a-c-master">{{ couponOrderInfo.payChannel | initDic(payChannelDic) }}</span>
                </div>
                <!-- <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付来源</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.orderSourceText?couponOrderInfo.orderSourceText:'-' }}</span>
                </div> -->
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付金额</span>
                    <span class="content a-c-master">￥{{ util.numFormat(couponOrderInfo.payAmount) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">退款金额</span>
                    <span class="content a-c-master">￥{{ util.numFormat(couponOrderInfo.refundAmount) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">实际费用</span>
                    <span class="content a-c-master">￥{{ util.numFormat(couponOrderInfo.payAmount - couponOrderInfo.refundAmount) }}</span>
                </div>
                <!-- <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">订单创建人</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.mobile?couponOrderInfo.mobile:'-' }}</span>
                </div> -->
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">创建人手机号</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.mobile?couponOrderInfo.mobile:'-' }}</span>
                </div>
                <!-- <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">订单支付人</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.mobile?couponOrderInfo.mobile:'-' }}</span>
                </div> -->
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付人手机号</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.mobile?couponOrderInfo.mobile:'-' }}</span>
                </div>
                <!-- <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">创建商户</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.placeCompanyName?couponOrderInfo.placeCompanyName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付商户</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.payCompanyName?couponOrderInfo.payCompanyName:'-' }}</span>
                </div> -->
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">退款原因</span>
                    <span class="content a-c-master">{{ couponOrderInfo&&couponOrderInfo.refundMsg?couponOrderInfo.refundMsg:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">券包信息</span>
                    <span class="content a-c-master">共{{ couponOrderInfo.couponNum || 0 }}张优惠券，已使用{{ couponOrderInfo.couponUsedNum || 0 }}张，已过期{{ couponOrderInfo.couponExpiredNum || 0 }}张，待使用{{ couponOrderInfo.couponNeedUseNum || 0 }}张</span>
                </div>
            </div>
            
            <div class="a-c-master a-fs-16 a-fw-b mt64" >支付信息明细</div>
            <el-table :border='true' :data="payInfo" style="width: 800px;margin-left:14px;margin-top: 14px">
                <el-table-column label=""  prop='desc'></el-table-column>
                <el-table-column label="支付金额" >
                    <template slot-scope="{ row }">
                        <span>￥{{ util.numFormat(row.amount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="退款金额">
                    <template slot-scope="{ row }">
                        <span>￥{{ util.numFormat(row.refoundAmount) }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="a-c-master a-fs-16 a-fw-b mt64" v-if="couponOrderInfo">活动信息</div>
            <div class="a-flex-rfsfs a-flex-wrap" v-if="couponOrderInfo">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">关联活动</span>
                    <span class="content">{{ couponOrderInfo&&couponOrderInfo.activityName?couponOrderInfo.activityName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">关联优惠券</span>
                    <div class="a-flex-cfsfs">
                        <div class="a-flex-rfsfs" v-for="(item,index) in couponOrderInfo.goodsInfos" :key="index">
                            <span class="content">(No.{{ item.couponId }}){{ item.couponName }}</span>
                            <span class="content a-c-blue font-point" @click="showCouponStation(item.stationVoList)">可用站点</span>
                        </div>
                    </div>
                    <span class="content a-c-master" v-if="!couponOrderInfo.goodsInfos || !couponOrderInfo.goodsInfos.length">无</span>
                </div>
            </div>
           
            <div style="height: 100px"></div>
        </el-card>
        <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="closePage">&nbsp;&nbsp;&nbsp;关闭&nbsp;&nbsp;&nbsp;</el-button>
        </div>
        <le-station-list ref="couponStation" :tabledata='couponStation'></le-station-list>
    </div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
import util from '../../utils/util'
export default {
    data() {
        return {
            util: util,
            couponOrderInfo: '',
            goodsInfo: null,
            activityInfo: null,
            id: '',
            couponStation: [],
            cardTypeDic: [],//卡片类型字典
            isTempcardDic: [],//是否临时卡字典
            isSystemDefaultDic: [],//是否系统默认卡字典
            activeCardDic: [],//激活模式字典
            needPostDic: [],//是否邮寄
            showModelDic: [],//可见模式字典
            payChannelDic: [],//支付渠道字典
            activityRewardsTypeDic: [],//活动类型字典
            activityTypeDic: [],//活动规则字典
            payInfo: [],//支付金额
        };
    },
    created () {
        this.$getDic('monthcardType').then(res=>{ this.cardTypeDic = res; })
        this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
        this.$getDic('isSystemDefault').then(res=>{ this.isSystemDefaultDic = res; })
        this.$getDic('activeCard').then(res=>{ this.activeCardDic = res; })
        this.$getDic('needPost').then(res=>{ this.needPostDic = res; })
        this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
        this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        this.$getDic('activityRewardsType').then(res=>{ this.activityRewardsTypeDic = res; })
        this.$getDic('activityType').then(res=>{ this.activityTypeDic = res; })
        this.id = this.$route.query.orderId
        if(this.id) this.getOrderInfo()
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.user,
        })
    },
    methods: {
        getOrderInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.couponOrderDetail,
                params: {
                    id: this.id
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.couponOrderInfo = res.result.data
                    this.goodsInfo = res.result.data.goodsInfo?JSON.parse(res.result.data.goodsInfo):''
                    this.activityInfo = res.result.data.activityList&&res.result.data.activityList.length?res.result.data.activityList[0]:''

                    // 优惠信息
                    let refoundAmountAll = 0
                    let discountArr = this.couponOrderInfo.orderDiscountInfoList.map(item=>{
                        refoundAmountAll += item.refundPayAmount
                        return {
                            ...item,
                            amount: item.discountPayAmount,
                            refoundAmount: item.refundPayAmount,
                            desc: this.$Config.discountTypeDic[item.discountType],
                            details: item.details ? JSON.parse(item.details) : ''
                        }
                    })
                    refoundAmountAll += this.couponOrderInfo.refundAmount
                    this.payInfo[0] = {
                        amount: this.couponOrderInfo.payAmount,
                        refoundAmount: this.couponOrderInfo.refundAmount,
                        desc: '现金'
                    }
                    this.payInfo = this.payInfo.concat(discountArr)
                }
            })
        },
        showCouponStation (row) {
            this.couponStation = row
            this.$refs['couponStation'].dialogVisible = true
        },
        closePage () {
            this.$store.dispatch("delView", this.$route);
            this.$router.back()
        }
    },
}
</script>

<style lang="scss" scoped>
.sceneDetails{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
}
.tab-line{
    width: 60px;
    height: 3px;
    border-radius: 1px;
    margin-top: 11px
}
.lineAc{
    background: #007AFF;
    
}
.lineUnAc{
    background: #ffffff;
}
.tabAc{
    color: #303133;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.tabUnAc{
    color: #606366;
    font-size: 12px;
    cursor: pointer;
}
.mb32{
    margin-bottom: 32px
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 21px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 100px;
    margin-right: 16px;
}
.content{
    width: 200px
}
.stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
</style>